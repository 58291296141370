/* eslint-disable prettier/prettier */

import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, HashRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CSpinner, useColorModes } from '@coreui/react-pro';
import './scss/style.scss';
import './scss/examples.scss';

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'));

const App = () => {
  const { isColorModeSet, setColorMode } = useColorModes('coreui-pro-react-admin-template-theme-bright');
  const storedTheme = useSelector((state) => state.theme);
  const [isLoggedIn, setIsLoggedIn] = useState(null); // Use `null` to indicate checking status

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0];
    if (theme) {
      setColorMode(theme);
    }

    if (isColorModeSet()) {
      return;
    }

    setColorMode(storedTheme);
  }, [isColorModeSet, setColorMode, storedTheme]);

  useEffect(() => {
    const checkAuth = async () => {
      const userEmail = localStorage.getItem('userEmail');
      const userUID = localStorage.getItem('userUID');

      if (userEmail && userUID) {
        console.log("hh");
        setIsLoggedIn(true);
        // navigate('/');
      } else {
        setIsLoggedIn(false);
        console.log("hhfalse");
        // navigate('/login');
      }
    };

    checkAuth();
  }, []);

  if (isLoggedIn === null) {
    return (
      <div className="pt-3 text-center">
        <CSpinner color="primary" variant="grow" />
      </div>
    );
  }

  return (
    <HashRouter>
      <Suspense
        fallback={
          <div className="pt-3 text-center">
            <CSpinner color="primary" variant="grow" />
          </div>
        }
      >
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="/apps/email/*" name="Email App" element={isLoggedIn ? <EmailApp /> : <Login />} />
          <Route path="/home" name="Home" element={isLoggedIn ? <DefaultLayout /> : <Login />} />
          <Route path="*" name="404" element={isLoggedIn ? <DefaultLayout /> : <Login />} />
         </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default App;
