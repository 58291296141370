/* eslint-disable prettier/prettier */
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Import for Firebase Storage

const firebaseConfig = {
    apiKey: "AIzaSyCbzlkFRY8btmcYIeTJtODSlW4-63qP3DU",
    authDomain: "effortless-exisd3.firebaseapp.com",
    projectId: "effortless-exisd3",
    storageBucket: "effortless-exisd3.appspot.com",
    messagingSenderId: "267499689797",
    appId: "1:267499689797:web:fd16c49b4af2bf4d69902c"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { auth, db, storage };
